import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill", "stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    height: _ctx.size,
    viewBox: "0 0 12 12",
    fill: "none"
  }, [
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M4.54873 8.99349L6.64873 7.72682L8.74873 9.01016L8.19873 6.61016L10.0487 5.01016L7.6154 4.79349L6.64873 2.52682L5.68206 4.77682L3.24873 4.99349L5.09873 6.61016L4.54873 8.99349ZM6.64873 9.29349L3.88206 10.9602C3.75984 11.0379 3.63206 11.0713 3.49873 11.0602C3.3654 11.049 3.24873 11.0046 3.14873 10.9268C3.04873 10.849 2.97095 10.7519 2.9154 10.6355C2.85984 10.5186 2.84873 10.3879 2.88206 10.2435L3.6154 7.09349L1.1654 4.97682C1.05428 4.87682 0.984951 4.76282 0.957395 4.63482C0.929395 4.50727 0.937617 4.38238 0.982062 4.26016C1.02651 4.13793 1.09317 4.03793 1.18206 3.96016C1.27095 3.88238 1.39317 3.83238 1.54873 3.81016L4.78206 3.52682L6.03206 0.560156C6.08762 0.426823 6.17384 0.326823 6.29073 0.260156C6.40717 0.19349 6.52651 0.160156 6.64873 0.160156C6.77095 0.160156 6.89051 0.19349 7.0074 0.260156C7.12384 0.326823 7.20984 0.426823 7.2654 0.560156L8.51539 3.52682L11.7487 3.81016C11.9043 3.83238 12.0265 3.88238 12.1154 3.96016C12.2043 4.03793 12.271 4.13793 12.3154 4.26016C12.3598 4.38238 12.3683 4.50727 12.3407 4.63482C12.3127 4.76282 12.2432 4.87682 12.1321 4.97682L9.68206 7.09349L10.4154 10.2435C10.4487 10.3879 10.4376 10.5186 10.3821 10.6355C10.3265 10.7519 10.2487 10.849 10.1487 10.9268C10.0487 11.0046 9.93206 11.049 9.79873 11.0602C9.66539 11.0713 9.53762 11.0379 9.41539 10.9602L6.64873 9.29349Z"
    }, null, 8, _hoisted_2),
    (_ctx.isFilledInside)
      ? (_openBlock(), _createElementBlock("path", {
          key: 0,
          fill: _ctx.color,
          stroke: _ctx.color,
          d: "M4.54873 8.99349L6.64873 7.72682L8.74873 9.01016L8.19873 6.61016L10.0487 5.01016L7.6154 4.79349L6.64873 2.52682L5.68206 4.77682L3.24873 4.99349L5.09873 6.61016L4.54873 8.99349Z"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}