
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    color: {
      type: String,
      default: '#2c2c2c',
    },
    isFilledInside: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 16,
    },
  },
});
